@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.buttons-container {
  &.center {
    justify-content: center;
  }

  .button {
    margin-bottom: $main-spacing * 1.5;
    width: 100%;
  }

  @include gt-md {
    display: flex;
    justify-content: flex-end;

    .button {
      margin-right: $main-spacing;
      margin-bottom: 0;
      width: auto;

      &:last-child {
        margin-right: 0;
      }
    }

    &.max {
      .button {
        width: 100%;
      }
    }
  }
}

@import "../../../../config/variables.scss";

.action-header {
  margin-bottom: $main-spacing * 2;
  max-height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event-add-transaction {
  margin-top: $main-spacing * 4;
}

@media (min-width: map-get($breakpoints, md)) {
  .special-action-quiz {
    margin-bottom: 2 * $main-spacing;
  }
}

@import "src/config/variables";

.my-profile-form {
  .my-profile-section-header {
    display: flex;
    justify-content: space-between;
    margin: $main-spacing * 2 0;
  }

  .field-type-fileDragDrop .input-container {
    padding: 0;
  }
}


@import "../../../config/variables";
@import "../../../config/mixins";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1002;
  animation-name: showModal;
  animation-duration: 0.15s;
  transition: opacity $transition-time/2 ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;

    .modal-body {
      transform: scale(1);
    }
  }

  .modal-wrapper {
    background-position: center;
    background-size: cover;
    width: 100%;
    background: $color-background;
  }

  .modal-body {
    position: relative;
    z-index: 1000;
    overflow: auto;
    max-height: 100vh;
    max-width: 90%;
    width: 100%;
    position: relative;
    box-shadow: 0 0 $main-spacing * 1.5 $main-spacing / -2 rgba(0, 0, 0, 0.5);
    transform: scale(0.9);
    transition: transform $transition-time/2 ease-in-out;
    background-color: $color-background;
    border-radius: $main-spacing / 5;
    p {
      max-width: 500px;
    }
  }

  &.full-screen {
    .modal-body {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }
}

@include gt-xs {
  .modal {
    .modal-body {
      width: auto;
    }
  }
}

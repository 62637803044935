@import "../../../config/variables.scss";

.table-wrapper {
  .api-table {
    width: 100%;
  }
  .api-table-wrapper {
    overflow-y: scroll;
    width: 100%;
  }
  .table {
    width: 100%;
  }
  th {
    max-width: 300px;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: left;
    padding: 0 ($main-spacing/1.5) ($main-spacing/1.5);
    &:first-child {
      padding-left: 0;
    }
  }
  tbody {
    tr {
      border-radius: $border-radius;
      height: 50px;
      &:nth-child(odd) {
        background: darken($color-background-secondary, 3%);
      }
      &.highlight {
        background: rgba($color-primary, 0.5);
      }

      &.dehighlight {
        opacity: 0.5;
        background: darken($color-background-secondary, 25%);
      }
    }
  }
  td {
    max-width: 300px;
    padding: $main-spacing/1.5;
    font-size: 1.4rem;
    vertical-align: middle;
    a {
      text-decoration: none;
      color: $color-primary;
      cursor: pointer;
    }
  }

  .search-fields {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar {
    .search-input {
      border-radius: $border-radius;
      outline: none;
    }
  }
}
.table-wrapper {
  a {
    color: $color-primary;
    text-decoration: none;
  }
  .list-element {
    margin-bottom: $main-spacing * 3;
    padding: $main-spacing;
    &:nth-child(odd) {
      background: darken($color-background-secondary, 3%);
    }
  }
  .list-row {
    .list-value {
      display: block;
      margin-bottom: $main-spacing;
      font-size: 1.4rem;
    }
    strong {
      font-weight: 600;
      display: block;
      margin: 0 0 $main-spacing 0;
      font-size: 1.5rem;
    }
    &.mobile .list-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
  .button {
    margin-right: 0.5 * $main-spacing;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .table-wrapper .search-bar {
    td .mobile {
      display: flex;
    }
  }
}

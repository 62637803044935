@import '../../../config/variables.scss';

.hidden {
  display: none;
}

.import-title.page-title{
  font-size: 1.5rem;
  margin-top: 2 * $main-spacing;
}

.import-form {
  margin: 3 * $main-spacing 0;

  form {
    width: 100%;

    .col {
      padding: 0;
    }
  }

  &.csv-form {
    align-items: center;
    padding: 0;

    span {
      display: none;
    }
    
    .input {
      // opacity: 0;
      position: relative;
      background-color: #fff;
      width: 100px;
      padding-left: 0;
    }
  }
}

@media (min-width: map-get($breakpoints, md)) {
 
  .import-form {
    .form-container {
      flex-direction: row;
    }
  }
}

@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.dht-action-header {
  margin-bottom: $main-spacing * 2;
}

.dht-action-content {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;

  img {
    display: block;
    margin: $main-spacing * 2 auto;

    @include gt-md {
      max-width: 80%;
    }
  }
}

@import "../../../config/variables.scss";

.home-news-mobile {
  margin-bottom: $main-spacing * 2;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  .title {
    font-size: 1.3rem;
    position: absolute;
    bottom: 20px;
    left: 0px;
    padding: $main-spacing;
    background-color: rgba($color: #000000, $alpha: 0.75);
    color: $color-background-secondary;
  }
}

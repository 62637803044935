@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.news-box {
  height: 175px;
  @include gt-lg {
    height: 225px;
  }

  position: relative;
  display: block;
  cursor: pointer;
  .title {
    font-size: 1.8rem;
    position: absolute;
    bottom: 20px;
    left: 0px;
    padding: $main-spacing;
    background-color: rgba($color: #000000, $alpha: 0.75);
    color: $color-background-secondary;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &.main {
    height: 365px;
    margin-bottom: 0;

    @include gt-lg {
      height: 465px;
    }
  }
}

.news-box-wrapper {
  margin: 0 ($main-spacing * -(0.5));
  margin-bottom: $main-spacing * 2;
  .left-side,
  .right-side {
    padding: 0 $main-spacing * 0.5;
  }
}

.news-box-column {
  margin-bottom: $main-spacing * 1.5;
  &:last-of-type {
    margin-bottom: 0;
  }
}

@import "src/config/variables";
@import "src/config/mixins";

.user-action-box {
  height: 150px;
  margin-bottom: $main-spacing * 1.5;
  padding: 0 ($main-spacing/2);
  text-decoration: none;

  flex: 0 0 50%;

  @include gt-md {
    flex: 0 0 25%;
    &.superuser-action-box {
      flex: 0 0 33.33%;
    }
  }

  .text {
    font-size: 1.6rem;
    color: $color-background;
    font-weight: 900;
    text-transform: uppercase;
  }

  .content-wrapper {
    position: relative;
    box-shadow: 0 0 1.5 * $main-spacing (-0.5 * $main-spacing) rgba($color-text, 0.5);
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: $main-spacing;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: hidden;
    cursor: pointer;

    .link {
      font-size: 1.9rem;
      margin: $main-spacing 0;
    }
  }
}

.user-action-icon {
  position: absolute;
  right: 0;
  top: 50%;
  .icon {
    svg {
      height: 150px;
      transform: translate(10%, -50%);
      width: auto;
      fill: $color-background-secondary;
      opacity: 0.1;
    }
  }
}

@import "../../../config/variables.scss";

.footer {
  min-height: 100px;
  padding-left: $desktop-nav-width;
  display: none;
}

.footer-container {
  border-top: 1px solid $color-text;
  padding: 2.5 * $main-spacing 0;
  height: 100%;

  a {
    text-decoration: none;
    color: $color-text;
    cursor: pointer;
  }
}

.privacy-panel {
  margin-top: $main-spacing;
}

.privacy {
  margin: $main-spacing 0;
  font-size: 1.1rem;
}
.footer-nav {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5 * $main-spacing 0 $main-spacing;
}

.footer-creaton {
  .icon {
    justify-content: flex-end;
    svg {
      height: 20px;
      width: auto;
    }
  }
}

@media (min-width: map-get($breakpoints, md )) {
  .footer {
    display: block;
  }
}

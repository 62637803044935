@import "../../../config/variables";

.notification {
  animation-name: show;
  animation-duration: $transition-time;
  border-radius: $main-spacing;
  cursor: pointer;

  padding: $main-spacing / 2;
  z-index: 1002;

  .alert {
    margin: 0;
    box-shadow: 0 0 $main-spacing/2 0 rgba(0, 0, 0, 0.25);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    margin-top: -$main-spacing;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@import "../../../config/variables.scss";

.user-image {
  width: $user-image-size-mobile;
  height: $user-image-size-mobile;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 1.5 * $main-spacing (-0.5 * $main-spacing) rgba($color-text, 0.5);
  margin-bottom: $main-spacing;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &.center {
    align-items: center;
    justify-content: center;
    margin: ($main-spacing * 2) auto;
  }

  @media (min-width: map-get($breakpoints, md)) {
    width: $user-image-size-desktop;
    height: $user-image-size-desktop;
  }
}

@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

%button {
  display: flex;
  width: auto;
  border-radius: $border-radius;
  background-color: $color-text;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: $font-primary;
  font-size: 1.4rem;
  border: none;
  box-shadow: $soft-shadow-bottom;
  padding: $main-spacing * 1.5 $main-spacing * 4;
  transition: $transition-time;
  outline: none;
  text-decoration: none;
  color: $color-background-secondary;
  &.ml-a {
    margin-left: auto;
  }
  &.underline {
    text-decoration: underline;
    box-shadow: unset;
    padding: 0;
    &:hover {
      background: none;
    }
  }

  &:link,
  &:active,
  &:visited {
    color: $color-background-secondary;
  }

  &:hover {
    background: darken($color-background, 5%);
    color: lighten($color-text-input, 0.5);
  }
}

.button {
  @extend %button;
  color: $color-background-secondary;
  &:link,
  &:active,
  &:visited {
    color: $color-background-secondary;
  }

  &:hover {
    background: darken($color-background, 5%);
    color: $color-text;
  }
}

.button-primary {
  @extend %button;
  background-color: $color-primary;
  color: $color-background-secondary;
  &.with-border {
    border: 1px solid $color-text;
  }
  &.reverse {
    background-color: $color-background-secondary;
    color: $color-text;
  }
  &.light {
    background-color: $color-background-secondary;
    border-color: $color-background-secondary;
    color: $color-primary;
  }

  &:link,
  &:active,
  &:visited {
    color: $color-background;
  }
  &:hover {
    background: lighten($color-primary, 15%);
    color: $color-background-secondary;
  }
}

.button-secondary {
  @extend %button;
  background-color: $color-active;
  color: $color-background-secondary;
  border: 1px solid transparent;
  &.reverse {
    background-color: $color-background-secondary;
    color: $color-active;
  }
  &:link,
  &:active,
  &:visited {
    color: $color-text;
  }
  &:hover {
    background-color: $color-background-secondary;
    color: $color-active;
    border: 1px solid $color-active;
  }
  &.no-hover {
    border: none;
  }
}

.button-light {
  @extend %button;
  background-color: $color-background-secondary;
  color: $color-background-secondary;
  border: 1px solid transparent;
  &.reverse {
    background-color: $color-background-secondary;
    color: $color-active;
  }
  &:link,
  &:active,
  &:visited {
    color: $color-text;
  }
  &:hover {
    background-color: darken($color-background-secondary, 15%);
  }
  &.no-hover {
    border: none;
  }
}

.button-danger {
  @extend %button;
  background: $color-danger;
  color: $color-background-secondary;
  &:hover {
    background: lighten($color-danger, 5%);
    color: $color-background-secondary;
  }
}

.button-success {
  @extend %button;
  background: $color-success;
  color: $color-background-secondary;
  &:hover {
    background: lighten($color-success, 5%);
    color: $color-background-secondary;
  }
}

.button-small {
  padding: $main-spacing $main-spacing;
  font-size: 1.2rem;
}

.button-big {
  padding: $main-spacing * 2 $main-spacing * 4;
  font-size: 1.8rem;
}

.button-disabled {
  background: $color-disabled;
  opacity: 0.5;
  color: $color-text;
  cursor: not-allowed;

  &:hover {
    background: $color-disabled;
    color: $color-text;
  }
}

.button-icon {
  display: flex;
  align-items: center;
}

.button-left-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 $main-spacing/2 0 0;
  }
  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}
.button-right-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 0 0 $main-spacing/2;
  }

  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}

.button-round {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.55rem;
}

.button-main {
  background-color: $color-main;
  color: $color-background-secondary;

  &:visited {
    color: $color-background-secondary;
  }
}

@import "src/config/variables";

.section-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: $main-spacing 0;

  &.highlight {
    font-size: 2.2rem;
    font-weight: 900;
  }
}

@import "../../../config/variables.scss";

@media (min-width: map-get($breakpoints , md )) {
  .import-form.add-user-form {
    margin: 3 * $main-spacing -1.5 * $main-spacing;
  }

  .import-form.add-user-form form {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5 * $main-spacing 0;
    justify-content: flex-start;
  }
}

@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.container {
  width: 100%;
  padding: 0 $main-spacing;
  margin: 0 auto;
  max-width: $max-page-width;
  &.dark {
    background-color: $color-primary;
  }

  @include gt-md {
    padding: 0 $main-spacing * 3;
  }
}

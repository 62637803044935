@import '../../../../config/variables.scss';

.quiz-buttons {
  margin-bottom: $main-spacing * 2;
  overflow-x: auto;
  .button-disabled {
    &.wrong-answer {
      border-color: #E30613;
    }
    &.good-answer {
      border-color: #31D4A3;
    }
  }
}

.quiz-question {
  font-size: 2.4em;
  padding: $main-spacing * 7 0;
  text-align: center;
  font-weight: 500;
}

.quiz-button {
  width: 100%;
  height: 100%;
  background-color: $color-background-secondary;
  color: $color-dark;
  box-shadow: unset;
  border: 3px solid $color-label;
  font-size: 1.8rem;
  text-align: center;
  overflow-x: auto;
  &.button-disabled {
    opacity: 1;
  }
  &:disabled {
    background-color: unset;
  }
}

.quiz-buttons-wrapper {
  align-items: stretch;
  margin-bottom: $main-spacing * 2;
}

.quiz-start-row {
  
  .col {
    margin: $main-spacing 0;
  }
  .entry-text {
    padding: 0 $main-spacing;
  }
}

.quiz-start-card {
  width: 100%;
  flex: 0 0 100%;
}

.quiz-result {
  text-align: center;
  font-size: 1.8rem;
}

.question-photo {
  width: 250px;
  height: 175px;
  object-fit: cover;
  margin-top: $main-spacing * 2;
}

@media (min-width: map-get($breakpoints, md)) {
  .quiz-start-row {
    flex-direction: column;
    align-items: center;
  }
  .quiz-start-card {
    width: 380px;
  }
  .quiz-button {
    padding: $main-spacing * 10 $main-spacing * 2;
  }
}
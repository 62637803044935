@import "../../../../config/variables.scss";

.rating-user-level {
  margin: $main-spacing 0;
  text-align: center;
  .level-points {
    font-weight: 400;
    color: $color-danger;
  }

  .progress-wrapper {
    position: relative;
    width: 80%;
    height: 2rem;
    margin: $main-spacing auto;
    background: darken($color-background-secondary, 10%);
  }

  .progress-bar {
    position: absolute;
    left: 0;
    height: 100%;
    background: rgb(0, 107, 145);
    background: -moz-linear-gradient(0deg, darken($color-primary, 10%), 0%, $color-primary 100%);
    background: -webkit-linear-gradient(0deg, darken($color-primary, 10%) 0%, $color-primary 100%);
    background: linear-gradient(0deg, darken($color-primary, 10%) 0%, $color-primary 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006b91",endColorstr="#029dd4",GradientType=1);
  }
}

@import "src/config/variables";

.article {
  position: relative;
  .title {
    margin-top: $main-spacing;
  }
  img {
    height: 240px;
  }
  &:first-of-type {
    .title {
      background-color: rgba($color-text, 0.85);
      color: $color-background-secondary;
      padding: $main-spacing * 1.5;
      font-size: 2.2rem;
      font-weight: 700;
      display: inline-block;
      position: absolute;
      bottom: 20px;
      left: 0px;
    }
    img {
      height: 400px;
    }
  }
}

@import "src/config/variables";

.card {
  margin-bottom: $main-spacing * 2;
  text-decoration: none;

  .card-wrapper {
    position: relative;
  }

  .deadline {
    display: flex;
    height: 40px;
    justify-content: flex-end;
    padding: .5 * $main-spacing;
    margin-top: -50px;
    position: relative;
    z-index: 1;
  }

  a {
    display: block;
    color: $color-text;
    text-decoration: none;
  }
  cursor: pointer;
  .title {
    font-size: 2.2rem;
  }
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  p.title {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  .quiz-shortcut {
    min-height: 50px;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: $color-text;
    color: $color-background-secondary;
    padding: 1.5 * $main-spacing $main-spacing $main-spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.special-task {
      background-color: $color-background-secondary;
      color: $color-text;
      text-transform: uppercase;
    }
  }
}

.quizzes-list {
  
  .quiz-shortcut {
    height: 70px;
  }
  img {
    height: 200px;
  }

}

@media (min-width: map-get($breakpoints , md )) {
  .quizzes-list {
    max-width: 33.3%;
  }
}
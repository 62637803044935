@import "../../../config/variables";
@import "../../../config/mixins";

.col {
  flex: 0 0 100%;
  padding: 0 ($main-spacing * 1);
  background-color: transparent;
  outline: none;
  border: none;

  &.center {
    justify-content: center;
    margin: auto;
  }
  &.auto {
    flex: 1;
  }
  &.ml-a {
    margin-left: auto;
  }
  &.mr-a {
    margin-right: auto;
  }
  &.hidden {
    visibility: hidden;
  }
  @for $i from 1 through 12 {
    &.col-xs-#{$i} {
      flex: 0 0 (($i/12) * 100%);
    }
  }
}

@include gt-sm {
  .col {
    @for $i from 1 through 12 {
      &.col-sm-#{$i} {
        flex: 0 0 (($i/12) * 100%);
      }
    }
  }
}

@include gt-md {
  .col {
    @for $i from 1 through 12 {
      &.col-md-#{$i} {
        flex: 0 0 (($i/12) * 100%);
      }
    }
  }
}

@include gt-lg {
  .col {
    @for $i from 1 through 12 {
      &.col-lg-#{$i} {
        flex: 0 0 (($i/12) * 100%);
      }
    }
  }
}

@import "../../config/variables.scss";
@import "../../config/mixins.scss";

.competition-rules {
  max-width: 900px;
  margin: 0 auto;
  img {
    display: block;
    margin: $main-spacing * 2 auto;
    @include gt-md {
      max-width: 80%;
    }
  }

  .no-marker {
    list-style: none;
  }
  .image-small {
    max-width: 300px;
  }

  .question-mark {
    display: block;
    font-weight: 900;
    font-size: 4rem;
    border: 2px solid $color-text;
    border-radius: 50%;
    margin: 0 auto;
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex: 0 0 auto;
    @include gt-md {
      margin: 0px $main-spacing * 2;
    }
  }

  .competition-rules-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include gt-md {
      flex-direction: row;
      align-items: center;
      margin: $main-spacing * 2 (-2 * $main-spacing);

      div {
        padding: 0px $main-spacing * 2;
      }
      img {
        flex: 0 1 auto;
        margin: 0px $main-spacing * 2;
      }
    }
  }
}

@import "../../../config/variables";

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 ($main-spacing * -1);
  &.justify-between {
    justify-content: space-between;
  }

  &.buttons-container {
    margin: 0;
    &.small-button {
      .button {
        width: auto;
      }
    }
    .button {
      width: 100%;
      margin-bottom: $main-spacing * 2;
    }
  }
  &.column {
    flex-flow: column nowrap;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.center {
    align-items: center;
    justify-content: center;
  }
}

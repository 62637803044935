@import '../../../../config/variables.scss';

.dashboard-gallery {
  padding: 3 * $main-spacing 0;
  .gallery-carousel {
    
    .carousel-item {
      padding: .5 * $main-spacing;
      
    }
  }

  .gallery-subtitle {
    margin: 1.5 * $main-spacing 0;
  }

  .gallery-subtitle{
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@media (min-width: map-get($breakpoints, md)) {

  .dashboard-gallery .gallery-carousel {
    height: 200px;
    .carousel-item {
      height: 200px;
    }
  }
}
@import '../../../../../config/variables.scss';

.create-quiz-form {
  display: flex;
  flex-direction: column;

  .quiz-photo{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
    .photo-wrapper {
      width: 250px;
      height: 175px;
      margin-top: $main-spacing * 2;
      border: 1px solid $color-disabled;
    }
  }

  .form-quiz-title {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .answears {
    display: flex;
    flex-direction: column;
    padding-left: $main-spacing * 3;
    .form-field {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input[type=text] {
        max-width: 80%;
      }
      input[type=radio] {
        max-width: 15%;
      }
    }
  }
  .form-field input {
    margin: $main-spacing 0;
  }
  .question-header {
    display: flex;
    padding: $main-spacing 0;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .create-quiz-form {
    .answears {
      .form-field {
        display: flex;
        justify-content: space-between;
        input[type=text] {
          max-width: 90%;
        }
        input[type=radio] {
          max-width: 5%;
        }
      }
    }
  }


}
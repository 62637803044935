@import "../../../config/variables.scss";

.actions-banner {
  margin-bottom: $main-spacing * 2;
  padding: 0 5px;

  button {
    height: 175px;
    padding: 2 * $main-spacing;
    background-color: $color-inactive;
    &:hover:enabled,
    &:disabled {
      background-color: $color-inactive;
    }
    &:disabled:hover span {
      display: none;
    }
    span {
      display: none;
    }
  }

  .carousel-item {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.no-actions-notice {
  margin: $main-spacing * 2 auto;
}

@media (min-width: map-get($breakpoints , md )) {
  .actions-banner {
    button,
    .carousel-item {
      height: 440px;
    }
  }
}

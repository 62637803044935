@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: $main-spacing 0;
  width: 100%;
  background: transparent;
  z-index: 998;

  &.narrow {
    height: $header-height/2;
  }
  .back-button {
    color: $color-secondary;
    font-size: 2.5rem;
    font-weight: 700;
    border: none;
    background: none;
    outline: none;
    text-decoration: none;
  }

  @include lt-md {
    &.hide-on-mobile {
      display: none;
    }
  }
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;
}

.header-brand {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.page-header-title {
  font-size: 1.6rem;
  color: $color-text;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  flex: 1;
  padding: $main-spacing * 2 $main-spacing;
  order: 2;
  flex: 0 0 100%;
  text-align: center;
}

@include gt-md {
  .header-logout {
    visibility: hidden;
  }
}

@include gt-md {
  .header-brand {
    order: -1;
    flex: 0 0 100%;
  }

  .page-header-title {
    order: 1;
    flex: 1;
    text-align: left;
    padding: 0 $main-spacing;
  }

  .page-header {
    padding-left: $desktop-nav-width;

    &.no-left {
      padding-left: 0;
    }
  }
}

@import "../../../../config/variables.scss";

.available-ratings {
  margin-bottom: $main-spacing * 2;
}

.rating-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 0 1.5 * $main-spacing (-0.5 * $main-spacing) rgba($color-text, 0.5);
  margin-right: $main-spacing;
}

.rating-user {
  display: flex;
  align-items: center;
}

@import "../../../config/variables";
@import "../../../config/mixins";
.pagination {
  margin: $main-spacing 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  font-weight: 300;
  li {
    margin: $main-spacing / 2 $main-spacing / 4;
  }
  flex-wrap: wrap;
  .previous,
  .next {
    width: 100%;
    button {
      padding: 0;
      height: 30px;
      width: 30px;
      margin-bottom: 0;
      border-radius: 5px;
    }
  }
  @include gt-sm {
    li {
      margin: 0 $main-spacing / 2;
    }
    .previous,
    .next {
      width: auto;
    }
  }
  .button-link {
    color: $color-active;
    background-color: $color-background-secondary;
    border-color: $color-active;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 5px;

    &.previous {
      width: auto;
      height: auto;
    }
  }
  .selected {
    .button-link {
      color: $color-active;
      background-color: $color-background-secondary;
      border: 1px solid $color-active
    }
  }
  .button-link-active {
    color: $color-background-secondary;
    background: $color-primary;
  }
  .button-active-primary {
    button {
      color: $color-background;
      background: $color-text;
    }
  }
  .disabled {
    .button {
      background-color: $color-disabled;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

@import "src/config/variables";
@import "src/config/mixins";

.home-page-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include gt-md {
    flex-direction: row;
  }
}

.home-left,
.home-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $main-spacing * 2;
  flex: 0 0 50%;
}

.home-left {
  background-color: $color-background-secondary;
  justify-content: center;

  @include lt-md {
    flex: 1;
  }

  @include gt-md {
    background-color: $color-text;
    align-items: flex-end;
    .home-content {
      padding: $main-spacing;
      padding-right: $main-spacing * 5;
    }
  }
}

.home-right {
  background-color: $color-text;

  @include lt-md {
    flex: 1;
  }

  @include gt-md {
    align-items: flex-start;
    background-color: $color-background-secondary;
    .home-content {
      padding-left: $main-spacing * 5;
    }
  }
}

.home-content {
  max-width: $max-page-width/2 -$main-spacing * 5;
  width: 100%;
}

.home-mobile {
  @include gt-md {
    display: none;
  }
}

.home-desktop {
  display: none;
  @include gt-md {
    display: block;
  }
}

.have-account {
  color: $color-secondary;
  text-align: center;
  font-size: 1.4rem;
  width: 100%;
  margin: $main-spacing 0 $main-spacing;
}

.welcome-text-home {
  margin-bottom: $main-spacing * 7;
  color: $color-background-secondary;
}

.button-login {
  display: block;
  background-color: #000;
  color: $color-background-secondary;
  border: 1px solid $color-background-secondary;
}

.mobile-brand {
  margin-bottom: $main-spacing * 8;
}

.home-page-wrapper {
  margin-top: auto;
}

@media screen and (max-height: 600px) {
  .welcome-text-home {
    margin-bottom: $main-spacing * 3;
  }
}

@media (min-width: map-get($breakpoints , md )) {
  .mobile-brand {
    display: none;
  }

  .button-login {
    display: none;
  }

  .button-register {
    background-color: $color-background-secondary;
    color: $color-main;
  }

  .mobile-login {
    display: none;
  }
  .desktop-login {
    display: block;

    .page-title {
      color: $color-text;
    }

    .input {
      height: auto;
    }

    .button {
      background-color: $color-active;
      width: 100%;
    }
  }
  .welcome-text-home {
    color: $color-background-secondary;
  }
}

@import '../../../config/variables.scss';

.back-arrow {
  justify-content: flex-start;
  display: flex;
  margin-bottom: $main-spacing;
  .icon {
    svg {
      width: 25px;
      height: auto;
      margin-bottom: $main-spacing;
    }
  }
  path {
    fill: $color-text;
  }
}

@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.input-container {
  position: relative;
  margin-bottom: $main-spacing * 2;
  display: flex;
  flex-direction: column;

  ::placeholder {
    color: $color-label;
  }

  &.nomargin {
    margin-bottom: 0;
  }

  &.margin {
    margin: $main-spacing * 1.5;
  }

  &.hidden {
    display: none;
  }

  label {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 1.4rem;
    min-height: 21px;
    margin-bottom: $main-spacing * 1.5;
    display: block;
    z-index: 1;

    @include gt-sm {
      top: $main-spacing + 3px;
    }
  }

  .input {
    @include form-input;
  }

  textarea.input {
    min-height: 100px;
    resize: vertical;
    padding-top: $main-spacing * 3;
  }
  &.with-icon {
    label {
      left: $main-spacing * 4;
    }
    .input {
      padding-left: $main-spacing * 4;
    }
  }
}

.input-wrapper {
  position: relative;
  height: 100%;
  margin-bottom: $main-spacing / 4;
}

.input-icon {
  position: absolute;
  left: $main-spacing * 1.5;
  top: $main-spacing;
  padding-right: $main-spacing * 1.2;
  width: 1.2rem;
  height: 1.2rem;
  z-index: 1;
}

.password-icon {
  position: absolute;
  right: 21px;
  bottom: 16px;
  z-index: 1;
}

.form-field {
  position: relative;
  height: 100%;
  margin-bottom: $main-spacing * 2;

  input {
    @include form-input;
  }

  textarea {
    @include form-input;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.text-danger {
  margin-top: $main-spacing/4;
  color: lighten($color-danger, 10%);
  font-weight: 500;
  font-size: 1rem;
}

.icon-box {
  cursor: pointer;
}

.text-input-container {
  width: 100%;
  input {
    width: 100%;
    height: $main-spacing * 4;
    border-radius: 7px;
    font-size: 1.1rem;
    border: 1px solid $color-text;
    appearance: none;
    padding: $main-spacing;
  }
}

.checkbox-outer-cont {
  margin-bottom: $main-spacing/2;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: $main-spacing * 3;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: $main-spacing/2;
  margin-bottom: $main-spacing/2;
  color: $color-text;
  font-weight: 300;

  &.dark-label {
    color: $color-text;
  }
}

.chekbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-size;
  width: $checkbox-size;
  background-color: #eee;
  border-radius: 7px;
  border: 1px solid $color-primary;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: $color-background-secondary;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label-label {
  margin-left: -$main-spacing * 1.5;
}

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  background-position: right $main-spacing * 1.5 center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: $color-background;
  background-image: url("/assets/images/arrow-down.svg");
  border: transparent;
  outline: none;
  height: 38px;
  color: $color-text;
  display: block;
  width: 100%;
  line-height: 1.5rem;
  transition: $transition-time background-color;
  @include form-input;
  padding-top: 0;
  padding-bottom: 0;
}

.form-field-errors {
  li {
    margin-top: $main-spacing * 0.25;
    color: lighten($color-danger, 10%);
    font-weight: 500;
    font-size: 1rem;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $main-spacing/2;
  input {
    width: $checkbox-size;
    margin-right: $main-spacing;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .form-field-title {
    display: block;
    margin-bottom: 0;
  }
}

.form-field-title {
  color: $color-text;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: $main-spacing * 1.5;
  display: block;
  z-index: 1;

  @include gt-sm {
    top: $main-spacing + 3px;
  }
}

@import "../../../config/variables";

.user-details {
  .top-col {
    display: flex;
    justify-content: center;
  }

  .name {
    font-size: 2.5rem;
    margin: (.4 * $main-spacing) 0;
    font-weight: 700;
  }
}

.contact-data {
  color: $color-main;
  font-size: 1rem;
  font-weight: 500;
}
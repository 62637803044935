@import "../../../config/variables";

.start-view {
  padding-top: $header-height/1.2;
}

.edit-option  {
  position: absolute;
  top: 25 * $main-spacing;
  right: 2 * ($main-spacing);
  display: none;

  .icon svg {
    fill: $color-text;
    height: 100%;
    padding-top: $main-spacing;
  }

  
}

@media (min-width: map-get($breakpoints, md)) {
  .start-view {
    padding-top: 0;
  }
  .edit-option {
    display: block;
  }
}
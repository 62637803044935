@import '../../../config/variables.scss';

.terms-link {
  display: flex;
  text-decoration: none;
  color: $color-active;
  justify-content: center;
  align-items: baseline;
  height: 50px;
  
  .terms-title {
    font-size: 2rem;
    margin: 0 $main-spacing;
  }
  
  .icon-wrapper .icon svg, .a {
    fill: $color-active;
  }
}
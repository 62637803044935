@import "src/config/variables";
@import "src/config/mixins";

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: relative;
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.4rem;
    flex-wrap: wrap;
  }

  .form-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &.grid {
    form {
      flex-direction: row;
      margin: 0 -1 * $main-spacing;

      > * {
        flex: 0 0 100%;
        padding: 0 $main-spacing;
      }
    }
    .form-field,
    .input-container {
      flex: 0 0 100%;
      padding: 0 $main-spacing;
    }
    @include gt-sm {
      .form-field,
      .input-container {
        flex: 0 0 50%;
      }
    }
    @include gt-md {
      .form-field,
      .input-container {
        flex: 0 0 25%;
      }
    }
    .field-type-checkbox,
    .field-type-wysywig,
    .field-type-fileDragDrop {
      flex: 0 0 100%;
    }
  }
}

@import "src/config/variables";

.user-details-dashboard {
  margin-bottom: $main-spacing * 3;
}

.entry-section {
  margin-top: -($user-image-size-mobile) + 20px;

  @media (min-width: map-get($breakpoints, md)) {
    margin-top: -($user-image-size-desktop / 2);
  }
}
@import "src/config/variables";
@import "src/config/mixins";

.transaction-carousel {
  padding: 0;
  .BrainhubCarousel__customArrows {
    z-index: 1;
    top: calc(50% - 9.5px);
    &.BrainhubCarousel__arrow--disable {
      opacity: 0;
    }
    &.BrainhubCarousel__custom-arrowLeft {
      left: 0;
    }
    &.BrainhubCarousel__custom-arrowRight {
      right: 0;
    }
  }

  .carousel-item-wrapper {
    height: calc(100vh - (140px + 80px));
    width: 100%;
    position: relative;
    text-align: center;
    .description-wrapper {
      position: absolute;
      text-align: left;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $main-spacing * 8 $main-spacing * 1.5 $main-spacing * 1.5;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
      color: $color-background-secondary;
    }
    .description {
      font-size: 1.5rem;
    }
    .by-who {
      font-size: 2rem;
      margin-bottom: $main-spacing;
      font-weight: 300;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.carousel-icon {
  width: 30px;
  .custom-svg {
    stroke-width: 5px;
    stroke: #000;
  }
  &.icon-wrapper {
    .icon {
      svg {
        width: 35px;
        height: 35px;

        @include gt-md {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

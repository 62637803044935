// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

$font-primary: "muller", sans-serif;

//COLORS
$color-primary: #029dd4;
$color-primary-lighten: #025ac3;
$color-secondary: #e43f5a;
$color-background: #f2f4f7;
$color-background-secondary: #fff;
$color-background-gray: rgb(245, 247, 249);
$color-danger: #a51727;
$color-notice: #bb9c71;
$color-success: #00a45a;
$color-inactive: #e8e8e8;
$color-warning: #ec8628;
$color-disabled: #aaa;
$color-nav: #8f8d8d;
$color-text: #000;
$color-label: #c1c7d0;
$color-text-input: $color-text;
$color-main: #e30613;
$color-active: #009dd4;
$color-dark: #000;

//SPACING
$main-spacing: 10px;
//SHADOWS
$soft-shadow: 0 0 $main-spacing * 2.5 (-$main-spacing/2) rgba(0, 0, 0, 0.15);
$soft-shadow-bottom: 0 $main-spacing $main-spacing (-$main-spacing/2) rgba(0, 0, 0, 0.15);

$transition-time: 0.3s;

$breakpoints: (
  xs: 368px,
  sm: 768px,
  md: 920px,
  lg: 1200px,
  xl: 1368px
);

$border-radius: 10px;
$border-round: 50%;
$checkbox-size: 15px;

$desktop-nav-width: 100px;
$header-height: 70px;
$header-height-desktop: 100px;

$user-image-size-mobile: 120px;
$user-image-size-desktop: 185px;

$max-page-width: 1200px;

@import "./variables.scss";

.field-type-staticSelect {
  min-height: 80px;

  &.margin-top {
    margin-top: $main-spacing;
    min-height: 40px;
  }

  div {
    background-color: $color-background;
    border-radius: $border-radius;
    border: none;
    outline: none;
    box-shadow: none;

    div {
      box-shadow: none;
      border-color: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

// @media (min-width: map-get($breakpoints, md)) {
//   .field-type-staticSelect div > div:first-of-type {
//     div {
//       height: 40px;
//     }
//   }
// }

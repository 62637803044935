@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";


.info-box {
  border-radius: 5px;
  min-height: 30px; 
  max-height: 40px;
  color: $color-background-secondary;
  word-wrap: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  padding: $main-spacing 2 * $main-spacing;

  &.info {
    color: $color-text;
    border: 1px solid $color-main;
  }

  &.main {
    background-color: $color-main;
  }

  &.active {
    background-color: $color-active;
  }
}
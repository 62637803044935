@import "src/config/variables";

.transaction-verify-actions {
  .content {
    padding: ($main-spacing * 1.5) ($main-spacing * 2);
  }
  .accept-box {
    text-align: center;
    padding-left: 0;
    .content {
      background-color: $color-background;
    }
  }
  .reject-box {
    padding-right: 0;
    color: $color-background-secondary;
    text-align: center;
    .content {
      background-color: #000;
    }
  }
}

@import '../../../config/variables.scss';

main {
  min-height: calc(100vh - 200px);
  padding: $header-height-desktop 0 $main-spacing * 7;

  &.dark-theme {
    background-color: $color-primary;
  }
  &.align-end {
    display: flex;
    align-items: flex-end;
  }
  &.no-left {
    padding-left: 0;
  }
  &.no-padding {
    padding: 0;
  }
}

@media (min-width: map-get($breakpoints , md )) {
  main {
    padding-left: $desktop-nav-width;
    &.align-end {
      display: flex;
      align-items: flex-start;
    }
  }
}

@import '../../../config/variables.scss';


.contact-form {
  padding-top: $main-spacing * 2;
  .form-field {
    width: 100%;
  }
  form {
    flex-flow: row wrap;
  }
  .form-buttons {
    padding: 0 ;
  }
}
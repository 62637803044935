@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.main-prize {
  max-width: 900px;
  margin: 0 auto;
  img {
    display: block;
    margin: $main-spacing * 2 0;

    &.center {
      margin: $main-spacing * 2 auto;
    }

    @include gt-md {
      max-width: 80%;
    }
  }

  .big {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .bigger {
    font-size: 1.9rem;
    font-weight: 700;
  }
}

@import "./src/config/variables.scss";

.article-content {
  padding: 0 $main-spacing * 1.5;
  margin-top: $main-spacing * 3;

  ul {
    padding-left: $main-spacing * 2;
    list-style-type: disc;

    li {
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: $main-spacing;
    }
  }

  td {
    vertical-align: middle;
  }

  .image {
    text-align: center;
  }

  img {
    max-width: 80%;
    margin: 0 auto;
    box-shadow: $soft-shadow-bottom;

    margin-bottom: $main-spacing;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .article-content {
    max-width: 800px;
    width: 100%;
    margin: $main-spacing * 3 auto 0;
  }
}

.preview-main-photo {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

@import "../../../config/variables.scss";

.action-banner {
  position: relative;
  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $main-spacing * 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@import "../../../config/variables.scss";

.background-image {
  height: $user-image-size-mobile - 40px;
  width: 100%;
}

@media (min-width: map-get($breakpoints, md)) {
  .mobile-dashboard-brand {
    display: none;
  }
  .background-image {
    height: 220px;
  }
}
@import "src/config/variables";

.mobile-nav {
  background-color: $color-background-secondary;
  padding: $main-spacing * 1.5 $main-spacing * 3;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;


  .last-item {
    order: 10;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .mobile-nav {
    display: none;
  }
}


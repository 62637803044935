@import "../../../../config/variables";

.dashboard-bottom {
  margin: ($main-spacing * 2) auto;

  .line-icon .icon svg {
    height: 64px;
  }
  
  @media (min-width: map-get($breakpoints, md)) {
    width: 70%;
  }

}



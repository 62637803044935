@font-face {
  font-family: "muller";
  src: url("../fonts/MullerBold.eot");
  src: url("../fonts/MullerBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerBold.woff2") format("woff2"), url("../fonts/MullerBold.woff") format("woff"),
    url("../fonts/MullerBold.ttf") format("truetype"), url("../fonts/MullerBold.svg#MullerBold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerLightItalic.eot");
  src: url("../fonts/MullerLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerLightItalic.woff2") format("woff2"), url("../fonts/MullerLightItalic.woff") format("woff"),
    url("../fonts/MullerLightItalic.ttf") format("truetype"),
    url("../fonts/MullerLightItalic.svg#MullerLightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerBlackItalic.eot");
  src: url("../fonts/MullerBlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerBlackItalic.woff2") format("woff2"), url("../fonts/MullerBlackItalic.woff") format("woff"),
    url("../fonts/MullerBlackItalic.ttf") format("truetype"),
    url("../fonts/MullerBlackItalic.svg#MullerBlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerHeavy.eot");
  src: url("../fonts/MullerHeavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerHeavy.woff2") format("woff2"), url("../fonts/MullerHeavy.woff") format("woff"),
    url("../fonts/MullerHeavy.ttf") format("truetype"), url("../fonts/MullerHeavy.svg#MullerHeavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerExtraBold.eot");
  src: url("../fonts/MullerExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerExtraBold.woff2") format("woff2"), url("../fonts/MullerExtraBold.woff") format("woff"),
    url("../fonts/MullerExtraBold.ttf") format("truetype"),
    url("../fonts/MullerExtraBold.svg#MullerExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerHairlineItalic.eot");
  src: url("../fonts/MullerHairlineItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerHairlineItalic.woff2") format("woff2"), url("../fonts/MullerHairlineItalic.woff") format("woff"),
    url("../fonts/MullerHairlineItalic.ttf") format("truetype"),
    url("../fonts/MullerHairlineItalic.svg#MullerHairlineItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerRegular.eot");
  src: url("../fonts/MullerRegular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerRegular.woff2") format("woff2"), url("../fonts/MullerRegular.woff") format("woff"),
    url("../fonts/MullerRegular.ttf") format("truetype"), url("../fonts/MullerRegular.svg#MullerRegular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerThin.eot");
  src: url("../fonts/MullerThin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerThin.woff2") format("woff2"), url("../fonts/MullerThin.woff") format("woff"),
    url("../fonts/MullerThin.ttf") format("truetype"), url("../fonts/MullerThin.svg#MullerThin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerBlack.eot");
  src: url("../fonts/MullerBlack.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerBlack.woff2") format("woff2"), url("../fonts/MullerBlack.woff") format("woff"),
    url("../fonts/MullerBlack.ttf") format("truetype"), url("../fonts/MullerBlack.svg#MullerBlack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerExtraBoldItalic.eot");
  src: url("../fonts/MullerExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/MullerExtraBoldItalic.woff") format("woff"),
    url("../fonts/MullerExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/MullerExtraBoldItalic.svg#MullerExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerHeavyItalic.eot");
  src: url("../fonts/MullerHeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerHeavyItalic.woff2") format("woff2"), url("../fonts/MullerHeavyItalic.woff") format("woff"),
    url("../fonts/MullerHeavyItalic.ttf") format("truetype"),
    url("../fonts/MullerHeavyItalic.svg#MullerHeavyItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerHairline.eot");
  src: url("../fonts/MullerHairline.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerHairline.woff2") format("woff2"), url("../fonts/MullerHairline.woff") format("woff"),
    url("../fonts/MullerHairline.ttf") format("truetype"),
    url("../fonts/MullerHairline.svg#MullerHairline") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerMedium.eot");
  src: url("../fonts/MullerMedium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerMedium.woff2") format("woff2"), url("../fonts/MullerMedium.woff") format("woff"),
    url("../fonts/MullerMedium.ttf") format("truetype"), url("../fonts/MullerMedium.svg#MullerMedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerBoldItalic.eot");
  src: url("../fonts/MullerBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerBoldItalic.woff2") format("woff2"), url("../fonts/MullerBoldItalic.woff") format("woff"),
    url("../fonts/MullerBoldItalic.ttf") format("truetype"),
    url("../fonts/MullerBoldItalic.svg#MullerBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerThinItalic.eot");
  src: url("../fonts/MullerThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerThinItalic.woff2") format("woff2"), url("../fonts/MullerThinItalic.woff") format("woff"),
    url("../fonts/MullerThinItalic.ttf") format("truetype"),
    url("../fonts/MullerThinItalic.svg#MullerThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerUltraLight.eot");
  src: url("../fonts/MullerUltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerUltraLight.woff2") format("woff2"), url("../fonts/MullerUltraLight.woff") format("woff"),
    url("../fonts/MullerUltraLight.ttf") format("truetype"),
    url("../fonts/MullerUltraLight.svg#MullerUltraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerUltraLightItalic.eot");
  src: url("../fonts/MullerUltraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerUltraLightItalic.woff2") format("woff2"),
    url("../fonts/MullerUltraLightItalic.woff") format("woff"),
    url("../fonts/MullerUltraLightItalic.ttf") format("truetype"),
    url("../fonts/MullerUltraLightItalic.svg#MullerUltraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerMediumItalic.eot");
  src: url("../fonts/MullerMediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerMediumItalic.woff2") format("woff2"), url("../fonts/MullerMediumItalic.woff") format("woff"),
    url("../fonts/MullerMediumItalic.ttf") format("truetype"),
    url("../fonts/MullerMediumItalic.svg#MullerMediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerRegularItalic.eot");
  src: url("../fonts/MullerRegularItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerRegularItalic.woff2") format("woff2"), url("../fonts/MullerRegularItalic.woff") format("woff"),
    url("../fonts/MullerRegularItalic.ttf") format("truetype"),
    url("../fonts/MullerRegularItalic.svg#MullerRegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "muller";
  src: url("../fonts/MullerLight.eot");
  src: url("../fonts/MullerLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MullerLight.woff2") format("woff2"), url("../fonts/MullerLight.woff") format("woff"),
    url("../fonts/MullerLight.ttf") format("truetype"), url("../fonts/MullerLight.svg#MullerLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

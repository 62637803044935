@import "src/config/variables";

.add-images-module {
  .desktop-input {
    display: none;
  }
  .mobile-input {
    position: relative;
    height: 150px;
    margin-bottom: $main-spacing;
    margin-left: auto;
    .input-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    .icon-wrapper .icon svg, .a {
      fill: $color-nav;
      opacity: 1;

    }
    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      height: 100%;
      background-color: $color-background;
      .title {
        font-size: 1.3rem;
        color: $color-nav;
        font-weight: 500;
        margin-top: $main-spacing * .5;
      }
    }
    .input-container {
      height: 100%;
      width: 100%;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  .img-wrapper {
    margin-bottom: $main-spacing;
    max-width: 100%;
    height: 150px;
    object-fit: cover;
    padding: 0 1.5 * $main-spacing;
    img {
      height: 100%;
      object-fit: cover
    }

    &.odd {
      padding-right: $main-spacing * .75;
    }
    &.even {
      padding-left: $main-spacing * .75;
    }
  } 
}
.loaded-files-wrapper {
  flex-direction: row;
}

@media (min-width: map-get($breakpoints, md)) {

  .add-images-module {
    .input {
      width: 100%;
    }
    .desktop-input {
      display: block;
      height: 400px;
      position: relative;
      margin-bottom: $main-spacing * 3;
      .text {
        font-size: 2.3rem;
      }
      .or {
        text-transform: uppercase;
        font-size: 1.9rem;
        display: block;
        margin: $main-spacing * 3 0;
      }
      .image {
        width: 130px;
        height: auto;
        margin-bottom: 30px;
      }
      .input-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        margin: 0;
        .input {
          height: 100%;
        }
      }
      .content-wrapper {
        background-color: $color-background;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
      }
    }
    .mobile-input {
      display: none;
    }
  }
  
  .loaded-files-wrapper {
    height: 40 * $main-spacing;
    width: 30%;
    background-color: $color-background;
    border-radius: 0 15px 15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;

    &.col {
      padding: 1.5 * $main-spacing;
    }

    .row {
      width: 100%;
    }
    
   .img-wrapper {
     display: block;
    &.odd, &.even {
      padding: 0;
    }

   }
  }
  .add-transition {
    .add-description-module
    .form-container {
      margin: 0 -($main-spacing * 1.5);
      form {
        flex-direction: row;
        flex-wrap: wrap;
  
        button[type=submit] {
          margin-left: auto;
        }
      }
    
      .form-field, .input-container {
        width: 25%;
        padding: 0 $main-spacing * 1.5;
        input, select {
          height: 4 * $main-spacing;
        }
        
      }
      .field-type-checkbox {
        width: 100%;
        margin: 0;
      }
    }
  }
}
@import 'src/config/mixins';
@import 'src/config/variables';

.forgot-password {
  color: $color-text;
  text-align: center;
  font-size: 1.4rem;
  width: 100%;
  margin: $main-spacing 0 $main-spacing * 1.5;
  text-decoration: none;
}


@media (min-width: map-get($breakpoints, md)) {
  .login-welcome {
    display: none;
  }
}
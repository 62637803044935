@import "../../../../config/variables";

.dashboard-item-wrapper {
  justify-content: center;
  color: $color-text;
  display: block;
  text-decoration: none;

  .item {
    padding: 0;
    justify-content: center;
    display: flex;
    justify-content: center;
    &.column {
      flex-flow: column nowrap;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
    }
  }

  .item-value {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    margin-left: $main-spacing / 2;
  }

  .item-name {
    margin-top: 8px;
    display: block;
    font-size: 9px;
    height: 12px;
    font-size: 1.3rem;
  }

  .dashboard-icon .icon svg {
    fill: $color-text;
    height: 100%;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .dashboard-item-wrapper {
    .item-value {
      font-size: 2.5rem;
    }
  }
}

@import "../../../../config/variables.scss";
@import "../../../../config/mixins.scss";

.modal-content {
  position: relative;
  z-index: 1;
  padding: $main-spacing;

  @include gt-sm {
    min-width: 500px;
  }
}

@import "src/config/variables";

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.round {
  border-radius: $border-round;
}
@import "src/config/variables";

.nav-icon {
  .a {
    fill: $color-nav;
    opacity: 1;
  }

  @media (min-width: map-get($breakpoints, md)) {
    margin-top: 2 * $main-spacing;
    .icon {
      height: 50px;
    }
  }
  
}

.active .a{
  fill: $color-main;
}

@import '../../../config/variables.scss';

.desktop-nav {
  width: $desktop-nav-width;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-background-secondary;
  box-shadow: 4px 0 8px $color-background;
  z-index: 1001;
  display: none;
  padding: (6 * $main-spacing) 0;

  .icon-wrapper .icon svg {
    width: 20px;
    height: 20px;
  }
  .nav-line-icon .icon svg {
    width: 57px;
  }
  .nav-follow {
    display: none;
  }
  
  @media (min-width: map-get($breakpoints, md)) {
    display: block; 
    .nav-follow {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      font-weight: bold;
      margin: $main-spacing * 2 auto;
    }
  }
}

@import "../../../config/variables.scss";

.brand {
  .icon svg {
    height: auto;
    width: 200px;
  }
}

@media (min-width: map-get($breakpoints, md)) {
  .brand {
    .icon svg {
      width: 260px;
    }
  }
  
}
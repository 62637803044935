@import '../../../config/variables.scss';

.icon-wrapper {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    &.to-left {
      justify-content: flex-start;
    }
    svg {
      width: $main-spacing * 1.5;
      height: $main-spacing * 1.5;
      fill: $color-text;
      transition: fill $transition-time;
    }
  }
  &.big {
    .icon {
      svg {
        width: $main-spacing * 2;
        height: $main-spacing * 2;
      }
    }
  }
}
